var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"page-container",staticStyle:{"margin-bottom":"4rem"}},[_c('Legend'),_c('SectionHeader',{attrs:{"name":'Запись на услугу',"hideOnMobile":true}}),_c('div',{staticClass:"services-list-wrapper-mobile"},[_c('SectionHeaderMobile',{staticStyle:{"padding-left":"1.5rem","padding-top":"1.5rem"},attrs:{"name":'Запись на услугу'}}),_c('div',{staticClass:"service-info-wrapper"},[_c('p',{staticClass:"service-name"},[_vm._v(_vm._s(_vm.serviceItem.name))]),_c('p',{staticClass:"toggle-about-service"},[_vm._v("Подробнее")]),_c('Price',{attrs:{"size":'large',"price":_vm.serviceItem.price}}),_c('Panel',{attrs:{"type":'info',"text":'Стоимость услуги может быть изменена в зависимости от вашего автомобиля'}})],1),_c('div',{staticClass:"flex-wrapper"},[_c('div',{staticClass:"collapse-header-wrapper",class:{
            'stage-current': _vm.stage==='car-data',
            'stage-done': 'car-data' in _vm.completeStages,
            'stage-hidden': _vm.stage != 'car-data' && !'card-data' in _vm.completeStages,
          }},[_c('p',{staticClass:"collapse-header"},[_vm._v(" Укажите данные вашего авто ")]),_c('b-img',{attrs:{"src":require('@/assets/complete.svg'),"width":24,"height":24}})],1),_c('b-collapse',{staticClass:"collapse-wrapper",attrs:{"visible":_vm.stage==='car-data'}},[_c('input',{staticClass:"input black",attrs:{"type":"tel","placeholder":'Марка'}}),_c('input',{staticClass:"input black",attrs:{"type":"tel","placeholder":'Модель'}})]),_c('div',{staticClass:"collapse-header-wrapper",class:{
            'stage-current': _vm.stage==='calendar',
            'stage-done': 'calendar' in _vm.completeStages,
            'stage-hidden': (_vm.stage != 'calendar') && !('calendar' in _vm.completeStages),
          }},[_c('p',{staticClass:"collapse-header"},[_vm._v(" Выберите удобную дату ")]),_c('b-img',{attrs:{"src":require('@/assets/complete.svg'),"width":24,"height":24}})],1),_c('b-collapse',{staticClass:"collapse-wrapper",attrs:{"visible":_vm.stage==='calendar'}},[_c('b-card',[_vm._v("Car data")])],1),_c('div',{staticClass:"collapse-header-wrapper",class:{
            'stage-current': _vm.stage==='time',
            'stage-done': 'time' in _vm.completeStages,
            'stage-hidden': (_vm.stage != 'time') && !('time' in _vm.completeStages),
          }},[_c('p',{staticClass:"collapse-header"},[_vm._v(" Выберите удобное время ")]),_c('b-img',{attrs:{"src":require('@/assets/complete.svg'),"width":24,"height":24}})],1),_c('b-collapse',{staticClass:"collapse-wrapper",attrs:{"visible":_vm.stage==='time'}},[_c('b-card',[_vm._v("Car data")])],1),_c('div',{staticClass:"collapse-header-wrapper",class:{
            'stage-current': _vm.stage==='accept',
            'stage-done': 'accept' in _vm.completeStages,
            'stage-hidden': (_vm.stage != 'accept') && !('accept' in _vm.completeStages),
          }},[_c('p',{staticClass:"collapse-header"},[_vm._v(" Подтверждение записи ")]),_c('b-img',{attrs:{"src":require('@/assets/complete.svg'),"width":24,"height":24}})],1),_c('b-collapse',{staticClass:"collapse-wrapper",attrs:{"visible":_vm.stage==='accept'}},[_c('b-card',[_vm._v("Car data")])],1)],1)],1),_c('div',{staticClass:"services-list-wrapper-mobile"},[_c('BackcallCard',{staticStyle:{"padding":"1.5rem !important"},attrs:{"name":'Остались вопросы?',"color":'black',"noBorder":true}})],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }