var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper",class:{
    'black': _vm.color==='black',
    'noBorder': _vm.noBorder == true,
  }},[_c('h1',{staticClass:"header"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"content-wrapper"},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.form-_vm.text ? _vm.form-_vm.text : _vm.defaultText)+" ")]),_c('div',{staticClass:"flex-wrapper"},[_c('div',{staticClass:"input-wrapper user-icon",attrs:{"id":"name-wrapper"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nameText),expression:"nameText"}],staticClass:"input",class:{
                    'black': _vm.color==='black',
                    'input-invalid': _vm.nameMaskValid == 1,
                    'input-valid': _vm.nameMaskValid == 2,
                },attrs:{"type":"name","placeholder":'Имя',"id":"name-number-input"},domProps:{"value":(_vm.nameText)},on:{"blur":_vm.handleNameBlur,"focus":_vm.handleNameFocus,"input":function($event){if($event.target.composing){ return; }_vm.nameText=$event.target.value}}})]),_c('div',{staticClass:"input-wrapper phone-icon",attrs:{"id":"phone-wrapper"}},[_c('input',{ref:"phoneNumber",staticClass:"input",class:{
                    'black': _vm.color==='black',
                    'input-invalid': _vm.phoneMaskValid == 1,
                    'input-valid': _vm.phoneMaskValid == 2,
                },attrs:{"type":"tel","id":"phone-number-input","placeholder":'Телефон'},on:{"blur":_vm.handlePhoneBlur,"focus":_vm.handlePhoneFocus}})]),_c('b-button',{staticClass:"vitan-button",attrs:{"disabled":(_vm.phoneMaskValid != 2) || (_vm.nameMaskValid != 2)}},[_vm._v(" Отправить ")])],1)]),_c('div',{staticClass:"content-wrapper"},[_c('p',{staticClass:"text"},[_vm._v(" Или свяжитесь с нами любым удобным для Вас способом ")]),_c('div',{staticClass:"flex-wrapper"},[_c('b-img',{staticClass:"image",attrs:{"src":require('@/assets/instagram.svg'),"alt":"Responsive image"},on:{"click":_vm.gotoInst}}),_c('b-img',{staticClass:"image",attrs:{"src":require('@/assets/vk.svg'),"alt":"Responsive image"},on:{"click":_vm.gotoVk}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }